export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const BILLING_DATA = 'BILLING_DATA';
export const EDIT_BILLING_DATA = 'EDIT_BILLING_DATA';
export const LOGIN_INDICATOR = 'LOGIN_INDICATOR';
export const BILLING_RECORD_DATA = 'BILLING_RECORD_DATA';
export const EDIT_BILLING_RECORD_DATA = 'EDIT_BILLING_RECORD_DATA';


export const BILLING_RECORD1 = 'BILLING_RECORD1';
export const BILLING_RECORD1_TOTAL_AMOUNT = 'BILLING_RECORD1_TOTAL_AMOUNT';
export const BILLING_RECORD1_TOTAL_QTY = 'BILLING_RECORD1_TOTAL_QTY';
export const BILLING_RECORD1_FINAL_BILL_AMOUNT = 'BILLING_RECORD1_FINAL_BILL_AMOUNT';

export const BILLING_RECORD2 = 'BILLING_RECORD2';
export const BILLING_RECORD2_TOTAL_AMOUNT = 'BILLING_RECORD2_TOTAL_AMOUNT';
export const BILLING_RECORD2_TOTAL_QTY = 'BILLING_RECORD2_TOTAL_QTY';
export const BILLING_RECORD2_FINAL_BILL_AMOUNT = 'BILLING_RECORD2_FINAL_BILL_AMOUNT';


export const BILLING_RECORD3 = 'BILLING_RECORD3';
export const BILLING_RECORD3_TOTAL_AMOUNT = 'BILLING_RECORD3_TOTAL_AMOUNT';
export const BILLING_RECORD3_TOTAL_QTY = 'BILLING_RECORD3_TOTAL_QTY';
export const BILLING_RECORD3_FINAL_BILL_AMOUNT = 'BILLING_RECORD3_FINAL_BILL_AMOUNT';

export const PAGE = 'page';
export const STARTDATE = 'startdate';
export const STARTTIME = 'starttime';
export const ENDDATE = 'enddate';
export const ENDTIME = 'endtime';
export const APPLY = 'apply';
export const SELECT = 'select';
export const SELECTED_CAMERAS = 'selected_cameras';
export const SOCKET = 'socket';

